@import "../../assets/styles/colors";

@import "../../assets/styles/colors";
.footer {
  background-color: $footer-background;
  width: 100%;
  //border-top: 1px solid $grey-border;
  margin-top: 20px;
  &__info {
    .result__contact {
      margin-top: 60px;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
    .result__contact h3 {
      font-size: 0.8125rem;
      margin-bottom: 0;
      font-weight: 600;
    }
    .result__contact .section-table {
      padding-top: 10px;
      width: 100%;
    }
    .result__contact .section-table .table-col.icon,
    .result__contact .section-table .table-col.name,
    .result__contact .section-table .table-col.value {
      //font-size: 0.6875rem;
      // line-height: 0.6875rem;
      font-size: 0.8rem;
      line-height: 0.8rem;
      color: black;
      width: auto;
      display: inline-block;

      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
        line-height: 2rem;
      }
    }
    .result__contact .section-table .table-row {
      //font-size: 0.6875rem;
      font-size: 0.8rem;
      color: black;
      padding: 1px 0;
    }
    .result__contact .section-table .table-col__wrapper {
      display: inline-block;
      margin-right: 5px;
    }
    .result__contact .section-table .table-col__wrapper:first-child {
      //width: 85px;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__logo-wrapper {
    width: 100%;
    img {
      display: block;
      height: 110px;
      margin: 0 auto;

      @media (max-width: 1199px) {
        img {
          height: 110px;
        }
      }
    }

    .rops {
      padding: 10px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    }

    .umb {
      padding: 12px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    }
  }
}
