@import "../../assets/styles/colors";

.search {
  &__banner {
    background-color: $primary;
    text-align: center;
    h1,
    h2 {
      color: $secondary;
      margin: 20px 0;
      font-weight: 600;
    }
  }
}
html.contrast{
  .search {
  &__banner {
    background-color: $primary-contrast;
    h1,
    h2 {
      color: $secondary-contrast;
    }
  }
}
}
