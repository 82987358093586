@import "../../assets/styles/colors";
@import "../../assets/styles/typography";

.article {
  background: $secondary;
  box-shadow: 0 10px 40px 0 rgb(217 224 231 / 80%),
    0 6px 20px 0 rgb(217 224 231 / 50%);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
  color: $text;
  padding: 20px;

  section {
    color: $text;
  }
  footer {
    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }

  section,
  footer,
  header {
    margin-top: 20px;
  }

  header {
      h2{
      //font-size: 1.3rem;
    }
    a, span{
      font-size: 1.1rem;
    }
  }
  .result__contact .section-table{
    width: 100%;
  }
  .result__contact .section-table .table-col.value a{
    font-size: 1rem;
    line-height: 1rem;
  }
}

html.contrast{
  .article{
    background: $secondary-contrast;

    header{
      color: $text-contrast;
    }

  section {
    color: $text-contrast;
  }
  footer {
    color: $primary-contrast;
  }
  }
}
