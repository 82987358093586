@import '../../assets/styles/colors';
@import '../../assets/styles/buttons';

.header {
  background-color: $header-background;
  width: 100%;
  height: 64px;
  position: relative;
  z-index: 10;
  vertical-align: middle;

  .col div {
    display: inline-block;
  }

  .nav {
    display: inline-block;
    a.nav-link {
      padding: 20px 20px 20px 0px;
      color: $primary;
      font-weight: 600;
      display: inline-block;
      &:hover,
      &:focus {
        color: $primary-hover;
        text-decoration: underline;
      }
      &:disabled {
        color: $primary-disabled;
      }
      &:visited {
        color: $primary-visited;
      }
    }
  }
  &__logo-wrapper {
    padding-right: 15px;
    display: inline-block;
  }
  &__logo {
    width: 40px;
  }
  .accessibility {
    width:auto;
    float: right;
    button {
      @extend .btn-secondary;
      padding: 0px;
      margin: 10px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-weight: bold;

      
      svg {
        vertical-align: middle;
      }
      &.accessibility__fontsize svg{
        vertical-align: text-top;
      }
    }
    
  }

  &--transparent {
    background-color: transparent;
    position: absolute;

    .nav {
      a.nav-link {
        padding: 20px 20px 20px 0px;
        color: $secondary;
        &:hover,
        &:focus {
          color: $secondary;
          text-decoration: underline;
        }
        &:disabled {
          color: $primary-disabled;
        }
        &:visited {
          color: $secondary;
        }
      }
    }

    .header__logo {
      display: none;
    }
    .accessibility {
      button {
        @extend .btn-secondary--white;
      }
    }
  }

  @media (max-width: 768px) {
    
    .nav a.nav-link {
      font-size: 0.75rem;
      display: inline-block;
      width: auto;
      padding-right: 10px;
    }
    .accessibility {
      button:first-of-type {
        margin-left:0;
      }
  }

    &--transparent{
      .accessibility {
        button {
          border-color: $primary;
          color: $primary;
          background-color: transparent;
          &:hover {
            background-color: $purple-overlay;
            border-color: $purple-overlay;
            color: $secondary;
          }
        }
      
    }
  }
}}

html.contrast {
  .header {
    background-color: $header-background;

    .nav {
      a.nav-link {
        color: $primary-contrast;
        &:hover,
        &:focus {
          color: $primary-hover-contrast;
        }
        &:disabled {
          color: $primary-disabled-contrast;
        }
        &:visited {
          color: $primary-contrast;
        }
      }
    }
    .accessibility {
      button {
        @extend .btn-secondary-contrast;
      }
    }

    &--transparent {
      background-color: transparent;
      .nav {
        a.nav-link {
          color: $secondary-contrast;
          &:hover,
          &:focus {
            color: $secondary-contrast;
          }
          &:disabled {
            color: $primary-disabled-contrast;
          }
          &:visited {
            color: $secondary-contrast;
          }
        }
      }
      .accessibility {
        button {
          @extend .btn-secondary--white-contrast;
        }
      }
    }
  }
}
