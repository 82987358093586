@import '../../assets/styles/colors';
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  font-size: 1rem;
  padding: 15px;

  &:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  &[disabled] {
    background-color: $primary-disabled;
    border-color: $primary-disabled;
  }
}
.btn-secondary {
  border-color: $primary;
  color: $primary;
  background-color: transparent;
  &:hover {
    background-color: $purple-overlay;
    border-color: $purple-overlay;
    color: $secondary;
  }
  &[disabled] {
    background-color: $primary-disabled;
    border-color: $primary-disabled;
    color: $secondary;
  }
  &-contrast {
    border-color: $primary-contrast;
    color: $primary-contrast;
    background-color: transparent;
    &:hover {
      background-color: $overlay-contrast;
      border-color: $overlay-contrast;
      color: $secondary-contrast;
    }
    &[disabled] {
      background-color: $primary-disabled-contrast;
      border-color: $primary-disabled-contrast;
      color: $secondary-contrast;
    }
  }
  &--white {
    border-color: $secondary;
    color: $secondary;
    background-color: transparent;
    &:hover {
      background-color: $secondary;
      border-color: $primary;
      color: $primary;
    }
  }
  &--white-contrast {
    border-color: $secondary-contrast;
    color: $secondary-contrast;
    background-color: transparent;
    &:hover {
      background-color: $secondary-contrast;
      border-color: $primary-contrast;
      color: $primary-contrast;
    }
  }
}

.btn-grey {
  border-color: $grey-border;
  color: $grey-border;
  background-color: transparent;
  &:hover {
    background-color: $grey-border;
    border-color: $grey-border;
    color: $secondary;
  }
  &[disabled] {
    background-color: $primary-disabled;
    border-color: $primary-disabled;
    color: $secondary;
  }
}
