@import './assets/styles/colors';
@import './assets/styles/typography';

html {
  font-size: 16px;
}
html.large {
  font-size: 20px;
}

body {
  background-color: $background;
}

a {
  color: $primary;
  &:hover,
  &:focus {
    color: $primary-hover;
  }
  &:disabled {
    color: $primary-disabled;
  }
  &:visited {
    color: $primary-visited;
  }
}

main {
  width: 100%;
  min-height: calc(100vh - 245px);
}
.header--transparent + main {
  min-height: calc(100vh - 180px);
}
.screen-readers-only{
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
