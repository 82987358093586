@import "../../assets/styles/colors";
@import "../../assets/styles/typography";

.alert{
  margin: 20px 0;
}
.alert-info{
  background-color: $purple-overlay2;
  color: $primary;
  border: $primary;
}