@import '../../assets/styles/colors';
@import '../../assets/styles/buttons';

.result-wrapper {
  display: flex;
  align-items: center;
  background: $secondary;
  box-shadow: 0 10px 40px 0 rgb(217 224 231 / 100%),
    0 6px 20px 0 rgb(217 224 231 / 80%);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
  color: $text;

  @media (max-width: 768px) {
    display: block;
  }
}

.result {
  padding: 0px;
  margin: 0;
  margin-bottom: 0;
  box-shadow: 0;
  border-radius: 0;
  width: 100%;

  & &__header {
    padding: 0 20px;

    .result-title {
      font-weight: 600;
    }

    .section-tags {
      margin-bottom: 10px;

      .tag {
        display: inline-block;
        position: relative;
        padding: 5px 10px;
        margin: 0 2px;
        background-color: $purple-overlay;
        color: $secondary;
        font-weight: 600;
        font-size: 0.75rem;
        border-radius: 8px;
        margin-bottom: 5px;
      }
    }
  }

  & &__section {
    padding: 0 20px;
    margin-top: 0;

    .section-description {
      margin-bottom: 15px;
      ul {
        padding-left: 20px;
        margin-bottom: 0;
      }
    }

    .section-title {
      font-weight: 600;
    }
  }

  & &__footer {
    display: block;
    color: $black;
    margin-top: 0;

    .col {
      padding: 20px;
    }

    .row {
      border-top: 1px solid $grey;
    }

    .row:first-of-type {
      margin-top: 20px;
    }

    .details-wrapper {
      padding: 10px 20px;
      font-size: 0.875rem;
      border-bottom: 1px solid #d9e0e7;
    }

    .details-wrapper:first-of-type {
      padding-top: 20px;
    }

    .section-title {
      color: $black;
      font-weight: bold;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding-left: 0px;
      font-size: 0.875rem;
      color: $black;
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .result__contact {
  margin: 10px 0;

  h3 {
    font-weight: bold;
  }

  .section-table {
    margin: 0;
    width: calc(100% - 200px);
    align-self: stretch;
    display: inline-grid;
    vertical-align: bottom;

    .table-row {
      padding: 5px 0;
      color: $black;
      font-size: 0.875rem;
      font-weight: bold;
      display: block;
    }

    .table-col__wrapper {
      display: inline-block;
      //width: calc(100% - 125px);
      &:first-child {
        vertical-align: top;
        //width: 125px;
      }
    }

    .table-col.icon,
    .table-col.name,
    .table-col.value {
      display: inline-block;
      font-weight: bold;
      vertical-align: middle;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
    .table-col.name {
      margin-right: 10px;
    }
    .table-col.icon {
      margin-right: 5px;
      font-size: 0.75rem;
      line-height: 0.75rem;
    }

    .table-col.value {
      font-weight: normal;
      color: $primary;
      word-wrap: break-word;
      display: block;
      line-height: 1.3rem;

      a {
        //word-break: break-all;
        text-align: center;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      text-align: left;
    }
  }
  .section-table.details {
    width: 200px;
    text-align: right;
    a {
      background-color: $primary;
      color: $secondary;
      padding: 15px;
      border-radius: 7px;
      text-decoration: none;
      display: block;
      svg {
        margin-bottom: 2px;
        margin-left: 10px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      .table-col.value {
        display: block;
      }
      a {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
}
}

html.contrast{
  .result .result__contact .section-table.details a{
    background-color: $primary-contrast;
    &:hover{
      background-color: $purple-dark;
    }
  }
  .result .result__header .section-tags .tag{
    background-color: $grey-contrast;
  }

  a{
    color:$purple-dark;
  }
  .result .result__contact .section-table .table-row{
    color: $text-contrast;
  }
  .result .result__footer .section-title{
    color: $text-contrast;
  }
  .result .result__footer ul{
    color: $text-contrast;
  }
  .result .result__footer .details-wrapper{
    border-color: $primary-contrast;
  }
  .result .result__footer .row{
    border-top: 1px solid $primary-contrast;
  }
}

