@import '../../assets/styles/colors';

.pagination {
  display: block;
  width: auto;
  text-align: center;
  li {
    display: inline-block;
  }
  .page-link:focus {
    box-shadow: none;
    color: $primary;
  }
  .page-link,
  .page-link:hover {
    color: $primary;
  }

  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: $secondary;
    background-color: $primary;
    border-color: $primary;
  }
}

html.contrast {
  .pagination {
    .page-link:focus {
      color: $primary-contrast;
    }
    .page-link,
    .page-link:hover {
      color: $primary-contrast;
    }

    .page-link.active,
    .active > .page-link {
      z-index: 3;
      color: $secondary-contrast;
      background-color: $primary-contrast;
      border-color: $primary-contrast;
    }
  }
}
