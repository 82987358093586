$purple: #632080;
$purple-light: #7e29a3;
$purple-dark: #30144b;
$purple-overlay: rgba(63, 20, 82, 0.8);
$purple-overlay2: rgba(99, 32, 128, 20%);

$white: #fff;
$black: hsl(217, 19%, 35%);

$grey-light: #edf2f8;
$grey: #D9E0E7;
$grey-dark: #707070;
$grey-border: hsl(213, 6%, 28%);

$primary: $purple;
$secondary: $white;
$text: $black;
$background: $grey-light;

$primary-hover: $purple-light;
$primary-visited: $purple-dark;
$primary-disabled: $grey;

$footer-background: $white;
$header-background: $white;


$primary-contrast: black;
$secondary-contrast: white;
$grey-contrast: $grey-dark;
$text-contrast: black;
$overlay-contrast: black;
$grey-border-contrast: $grey-dark;
$primary-hover-contrast: $purple-dark;
$primary-visited-contrast: $purple-dark;
$primary-disabled-contrast: $grey-dark;