@import "../../assets/styles/colors";
.multiselect {
  &__inner__control,
  .css-13cymwt-control:hover {
    border: 1px solid $grey-border;
  }
  &__inner__control,
  .css-13cymwt-control {
    padding: 15px;
    color: $text;
    margin-bottom: 15px;
    border-radius: 0.375rem;
    max-height: 55px;
    height: 55px;
    overflow: hidden;
    background-color: $secondary;
    border-color: $grey-border;
    &--is-focused,
    &--is-focused.css-t3ipsp-control,
    &--is-focused.css-t3ipsp-control:hover {
      border-color: $primary;
      box-shadow: 0 0 0.1rem 0.1rem $purple-overlay;
    }
    &--is-disabled,
    &--is-disabled.css-16xfy0z-control {
      background-color: $secondary;
      border-color: $primary-disabled;
      color: $primary-disabled;
      .multiselect__inner__placeholder {
        color: $primary-disabled;
      }
    }
  }
  &__inner__placeholder,
  .css-1jqq78o-placeholder {
    color: $text;
  }
  &__inner__indicator-separator,
  .css-1u9des2-indicatorSeparator {
    background-color: $secondary;
  }
  .css-1xc3v61-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    background-color: $secondary;
    padding: 0 5px;
  }
  .css-1uxr3e-ValueContainer {
    padding: 0;
  }
  .css-3w2yfm-ValueContainer,
  .css-1fdsijx-ValueContainer {
    padding-left: 0;
  }
  .css-3w2yfm-ValueContainer,
  .css-1dimb5e-singleValue {
    max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  &__option-value {
    width: calc(100% - 30px);
  }
  &__option_checkbox,
  .form-check-input {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.25em;
    margin: 0;
    margin-left: 5px;
    border-color: $grey-border;

    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  &__option {
    display: block;
  }

  .multiselect__inner__menu {
    z-index: 10;
    .multiselect__inner__option {
      &--is-focused{
        background-color: $purple !important;
        color: $secondary !important;
        input[type='checkbox']{
          border-color: $secondary !important;
        }
      }

    }
  }
  .css-tr4s17-option {
    background-color: $purple;
  }
  .css-d7l1ni-option {
    background-color: $purple-overlay;
    color: $secondary;
  }
  .css-d7l1ni-option:active {
    background-color: $purple-overlay;
    color: $secondary;
  }
  .css-10wo9uf-option:active {
    background-color: $purple-light;
  }
  .css-1hb7zxy-IndicatorsContainer {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 60px;
    justify-content: end;
  }
}

html.contrast{
  .multiselect {
    &__inner__control,
    .css-13cymwt-control:hover {
      border: 2px solid $primary-contrast;
    }
    &__inner__control,
    .css-13cymwt-control {
      color: $text-contrast;
      background-color: $secondary-contrast;
      border-color: $primary-contrast;
      border-width: 2px;
      &--is-focused,
      &--is-focused.css-t3ipsp-control,
      &--is-focused.css-t3ipsp-control:hover {
        border-color: $primary-contrast;
        box-shadow: 0 0 0.1rem 0.1rem $overlay-contrast;
      }
      &--is-disabled,
      &--is-disabled.css-16xfy0z-control {
        background-color: $secondary-contrast;
        border-color: $primary-disabled-contrast;
        color: $primary-disabled-contrast;
        border-width: 1px;
        .multiselect__inner__placeholder {
          color: $primary-disabled-contrast;
        }
      }
    }
  &__option_checkbox,
  .form-check-input {
    border-color: $primary-contrast;

    &:checked {
      background-color: $primary-contrast;
      border-color: $primary-contrast;
    }
  }
    &__inner__placeholder,
    .css-1jqq78o-placeholder {
      color: $text-contrast;
    }

    .multiselect__inner__menu {
    z-index: 10;
    .multiselect__inner__option {
      &--is-focused{
        background-color: $primary-contrast !important;
        color: $secondary-contrast !important;
        input[type='checkbox']{
          border-color: $secondary-contrast !important;
        }
      }

    }
  }
  .css-tr4s17-option {
    background-color: $primary-contrast;
  }
  .css-d7l1ni-option {
    background-color: $overlay-contrast;
    color: $secondary-contrast;
  }
  .css-d7l1ni-option:active {
    background-color: $overlay-contrast;
    color: $secondary-contrast;
  }
  .css-10wo9uf-option:active {
    background-color: $primary-contrast;
  }
  }
}
