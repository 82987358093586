@import "../../assets/styles/colors";
@import "../../assets/styles/typography";

form,
.form {
  color: $text;
  &-control,
  input[type="text"],
  input[type="search"],
  select {
    padding: 15px;
    color: $text;
    margin-bottom: 15px;
    border-color: $grey-border;
    background-color: $secondary;

    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0.1rem 0.1rem $purple-overlay;
    }
  }
  input:-internal-autofill-selected,
  input:-webkit-autofill,
  input:-webkit-autofill:focus  {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &-check {
    label {
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      padding: 10px;
    }

    .form-check-input {
      height: 1.5rem;
      width: 1.5rem;
      border-color: $grey-border;

      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
      &:focus,
      &:focus-visible,
      &:focus-within {
        border-color: $grey-border;
        box-shadow: none;
        outline: 0;
      }
    }
  }

  &-label {
    background: $secondary;
    position: absolute;
    z-index: 2;
    font-size: 0.8125rem;
    margin: 0;
    margin-left: 11px;
    margin-top: -15px;
    padding: 5px;
    &.disabled {
      // color: $primary-disabled;
    }
  }

  .btn-primary {
    background-color: $primary;
    border-color: $primary;
    font-size: 1rem;
    padding: 15px;

    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
    }
    &[disabled] {
      background-color: $primary-disabled;
      border-color: $primary-disabled;
    }
  }

  ::-webkit-input-placeholder {
    color: $text;
  }
  :-moz-placeholder {
    color: $text;
    opacity: 1;
  }
  ::-moz-placeholder,
  :-moz-placeholder {
    color: $text;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $text;
  }
  ::-ms-input-placeholder {
    color: $text;
  }
  ::placeholder {
    color: $text;
  }
}
html.contrast{
  form,
  .form {
    color: $text-contrast;
    &-control,
    input[type='text'],
    input[type='search'],
    select {
      color: $text-contrast;
      border-color: $primary-contrast;
      background-color: $secondary-contrast;
      border-width: 2px;
    }
    &-label {
      background: $secondary-contrast;
      &.disabled {
        color: $primary-disabled-contrast;
      }
    }

    ::-webkit-input-placeholder {
      color: $text-contrast;
    }
    :-moz-placeholder {
      color: $text-contrast;
      opacity: 1;
    }
    ::-moz-placeholder,
    :-moz-placeholder {
      color: $text-contrast;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $text-contrast;
    }
    ::-ms-input-placeholder {
      color: $text-contrast;
    }
    ::placeholder {
      color: $text-contrast;
    }

    .btn-primary {
      background-color: $primary-contrast;
      border-color: $primary-contrast;
      border-width: 2px;

      &:hover {
        background-color: $primary-hover-contrast;
        border-color: $primary-hover-contrast;
      }
      &[disabled] {
        background-color: $primary-disabled-contrast;
        border-color: $primary-disabled-contrast;
      }
    }
  }
}
