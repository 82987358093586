@import "../../assets/styles/colors";
@import "../../assets/styles/keyframes";

.spinner-container {
  width: 100%;
  height: 100%;
  background: $grey-light;
  z-index: 100;
  position: fixed;
  top:0;
  left: 0;
  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
  .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border: 10px solid $grey-light;
  border-top: 10px solid $black;
  border-radius: 50%;
  animation: spinner 0.5s linear infinite;
  z-index: 101;
}
}


.content-spinner__wrapper  {
  width: 100%;
  height: 100px;
  text-align: center;
  position: relative;
  padding: 10px;
  .content-spinner {
    width: 40px;
    height: 40px;
    border: 5px solid $grey-light;
    border-top: 5px solid $black;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    left: 50%;
    top:10px;
    margin-left: -20px;
  }
  p{
    padding-top: 40px;
  }
  &.visible {
    display:block;
  }
  &.hidden {
    display: none;
  }
}


.results-wrapper {
  &.visible {
    display:block;
  }
  &.hidden {
    display:none;
  }
}
