@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,600;1,100;1,400;1,600&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

//3rem - 48px
//2rem - 32px
//1.5rem - 24px
//1.25rem - 20px
// 1 rem - 16px
// 0.875rem - 14px
// 0.8125rem - 13px
// 0.75rem - 12px
// 0.6875rem - 11px
//

h1 {
  font-size: 3.0rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4{
  font-size: 1rem;
}
h5{
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.0rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
}


