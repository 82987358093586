@import "../../assets/styles/colors";

.banner {
  height: 400px;
  background-color: #3d195f;
  background-image: url("../../assets/images/banner2.webp");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  background-size: auto 100%;

  @media (max-width: 768px) {
    height: 250px;
  }

  &__content {
    text-align: center;
    vertical-align: middle;
    margin-top: 80px;
    div{
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__logo-wrapper {
    align-items: end;
  }
  &__logo {
    height: 150px;
    border: solid 3px $secondary;
    margin-right: 20px;
    @media (max-width: 768px) {
      height: 80px;
    }
  }
  &__headline {
    align-items: start;
    text-align: left;
    h1 {
      color: $secondary;
      margin: 20px 0;
      font-weight: 600;
    }
  }
}

html.contrast{
  .banner{
    background: $primary-contrast;
  }
}

