.about{

  &__header{
    h2{
      font-weight: 600;
      text-align: center;
    }
  }
  &__section{
    ul{
      margin-top: 15px;
      padding-left: 15px;
    }
  }

  .article{
    padding: 40px 100px;
     @media (max-width: 768px) {
      padding: 20px;
     }
  }
}