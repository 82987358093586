@import "../../assets/styles/colors";

.search-widget {
  position: relative;

  &--small {
    > div {
      margin-top: -90px;
      @media (max-width: 768px) {
        margin-top: -50px;
      }
    }
    .search-widget__form {
      background-color: $secondary;
      border-radius: 15px;
      box-shadow: 0 10px 40px 0 rgb(209 209 209 / 17%),
        0 6px 20px 0 rgb(189 189 189 / 16%);
      padding: 20px;
    }
  }
  &--large {
    background-color: $secondary;
    > div {
      background-color: $secondary;
      padding: 20px 0;
    }

    .search-widget__form {
      background-color: $secondary;
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
    }
  }

  &--icon {
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
  }

  #search-widget--searchbar {
    padding-left: 50px;
  }

  .btn-primary{
    max-height: 55px;
  }
}
